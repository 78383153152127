import commonHelper from '@/app/utility/common.helper.utility';
import CreateNewBill from '../../billing/createNewBill';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { ModelSelect, BasicSelect } from 'vue-search-select';
import { format } from 'date-fns';
export default {
  name: 'addFmsGenerateBatchBill',
  components: {
    DatePicker,
    ModelSelect,
    BasicSelect,
    CreateNewBill
  },
  props: ['selectedBatch'],
  watch: {
    currentPage: function() {
      this.getBillBatchDataById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getBillBatchDataById();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  data() {
    return {
      billData: null,
      billBatchHdrId:null,
      showBillingDetailsModel: false,
      showErrorDetailsModal:false,
      payload: null,
      lovFieldName: null,
      lovId: null,
      keyValue: {
        value: null,
        text: null
      },
      showAdvanceSearch: false,
      selectedBatchData: {
        batch_name: null,
        bill_batch_id: null,
        bill_cycle_hdr_id: null,
        bill_date: null,
        bill_period_id: null,
        cycle_name: null,
        due_date: null,
        formatted_bill_date: null,
        formatted_due_date: null,
        formatted_gl_date: null,
        formatted_value_date: null,
        gl_date: null,
        le_id: null,
        legal_entity: null,
        ou_id: null,
        billing_period_from_to: [],
        period_from: null,
        period_to: null,
        plan_name: null,
        prj_name: null,
        status: null,
        system_batch_name: null,
        value_date: null,
        plan_unit_type_vset_meaning: null
      },
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      selectAllCheckBox: false,
      vsetCode: null,
      showBatchBillDetailGrid: false,
      setTimeVsetCode: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      unsubscribe: null,
      editMode: true,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      batchName: null,
      customerName: null,
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedOU: {
        value: null,
        text: null
      },
      selectedBillingCycle: {
        value: null,
        text: null
      },
      selectedFmsProj: {
        value: null,
        text: null
      },
      selectedBillStatus: {
        value: null,
        text: null
      },
      periodFrom: null,
      periodTo: null,
      billDate: null,
      dueDate: null,
      glDate: null,
      valueDate: null,
      createdBy: null,
      searchBatchBillList: [],
      selectedTower: {
        value: null,
        text: null
      },
      batchBillFiedls: [
        {
          key: 'selectbox',
          label: 'Select All',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'df',
          stickyColumn: true,
          variant: 'primary',
          label: 'Final',
          class: 'stickyColumn'
        },
        {
          key: 'status'
        },
        {
          key: 'error_log'
        },
        {
          key: 'entity_name',
          label: 'Fms / Lease Unit'
        },
        {
          key: 'customer_site_name',
          label: 'Customer Site'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'bill_no'
        },
        {
          key: 'bill_date'
        },
        {
          key: 'due_date'
        },
        {
          key: 'value_date'
        },
        {
          key: 'gl_date'
        },
        {
          key: 'base_amount',
          label: 'Principal Amount'
        },
        {
          key: 'tax_amount'
        },
        {
          key: 'total_amount'
        },
        {
          key: 'request_num'
        },
        {
          key: 'count'
        }
      ],
      projectList: [
        {
          value: null,
          text: null
        }
      ],
      sectorList: [
        {
          value: null,
          text: null
        }
      ],
      pocketList: [
        {
          value: null,
          text: null
        }
      ],
      towerList: [
        {
          value: null,
          text: null
        }
      ],
      floorList: [
        {
          value: null,
          text: null
        }
      ],
      unitList: [
        {
          value: null,
          text: null
        }
      ],
      subUnitList: [
        {
          value: null,
          text: null
        }
      ],
      selectedProject: {
        value: null,
        text: null
      },
      selectedSector: {
        value: null,
        text: null
      },
      selectedPocket: {
        value: null,
        text: null
      },
      selectedFloor: {
        value: null,
        text: null
      },
      selectedUnit: {
        value: null,
        text: null
      },
      selectedSubUnit: {
        value: null,
        text: null
      },
      tower: null,
      floor: null,
      unit: null,
      errorData:[],
      errorFields:[
        {
          key:'entity_type'
        },
        {
          key:'error_msg'
        }
      ]
    };
  },
  mounted() {
    this.selectedBatch.plan_unit_type_vset === 'FMS_SUB_UNIT'
      ? (this.tower = 'NFA_FMS_TOWER')
      : (this.tower = 'LEASE_TOWER_LIST');
    this.selectedBatch.plan_unit_type_vset === 'FMS_SUB_UNIT'
      ? (this.floor = 'NFA_FMS_FLOOR')
      : (this.floor = 'LEASE_FLOOR_LIST');
    this.selectedBatch.plan_unit_type_vset === 'FMS_SUB_UNIT'
      ? (this.unit = 'NFA_FMS_UNIT')
      : (this.unit = 'LEASE_UNIT_LIST');
    this.batchData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'saveqaadasd') {
          this.batchBillGenerate();
        }
        if (actionName === 'save') {
          this.batchBillGenerate();
        }
        if (actionName === 'download') {
          this.loader = true;
          const downloadpayload = { ...this.payload };
          downloadpayload.pagination._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'fmsTransaction/getBillBatchesById',
            'FMS-Batch-Bill-Detail',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    batchBillGenerate() {
      const filterPayloadData = this.searchBatchBillList.filter(
        data => data.selectbox
      );
      const getPayload = filterPayloadData.map(data => {
        return {
          batch_id: data.bill_batch_id,
          bill_type: data.df ? 'F' : 'D',
          fms_subunit_id: data.fms_sub_unit_id
        };
      });
      if (getPayload && getPayload.length > 0) {
        const payload = {
          batches: getPayload
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTransaction/saveBatchBill', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.isSuccess = true;
              this.respMessage = response.data.message;
              this.getBillBatchDataById();
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    batchData() {
      if (this.selectedBatch) {
        this.billBatchHdrId = this.selectedBatch.bill_batch_id;
        this.selectedBatchData.legal_entity = this.selectedBatch.legal_entity;
        this.selectedBatchData.cycle_name = this.selectedBatch.cycle_name;
        this.selectedBatchData.prj_name = this.selectedBatch.project_name;
        this.selectedBatchData.plan_unit_type_vset_meaning = this.selectedBatch.plan_unit_type_vset_meaning;
        this.selectedBatchData.plan_name = this.selectedBatch.plan_name;
        this.selectedBatchData.billing_period_from_to = [
          new Date(this.selectedBatch.period_from),
          new Date(this.selectedBatch.period_to)
        ];
        // this.selectedBatchData.period_from = this.selectedBatch.period_from
        //   ? new Date(this.selectedBatch.period_from)
        //   : null;
        // this.selectedBatchData.period_to = this.selectedBatch.period_to
        //   ? new Date(this.selectedBatch.period_to)
        //   : null;
      }
    },
    getBillBatchDataById() {
      this.payload = {
        billBatchId: this.selectedBatch.bill_batch_id,
        pagination: {
          _page: this.currentPage - 1,
          _limit: this.perPage
        },
        searchParams: {
          bill_date_from: this.billDate
            ? format(this.billDate[0], appStrings.DATEFNSFORMAT)
            : null,
          bill_date_to: this.billDate
            ? format(this.billDate[1], appStrings.DATEFNSFORMAT)
            : null,
          bill_no: null,
          created_by: this.createdBy,
          customer_name: this.customerName,
          due_date_from: this.dueDate
            ? format(this.dueDate[0], appStrings.DATEFNSFORMAT)
            : null,
          due_date_to: this.dueDate
            ? format(this.dueDate[1], appStrings.DATEFNSFORMAT)
            : null,
          floor_id: this.selectedFloor.value,
          project_id: this.selectedBatch.project_id,
          fms_sub_unit_id: this.selectedSubUnit.value,
          gl_date_from: this.glDate
            ? format(this.glDate[0], appStrings.DATEFNSFORMAT)
            : null,
          gl_date_to: this.glDate
            ? format(this.glDate[1], appStrings.DATEFNSFORMAT)
            : null,
          status: this.selectedBillStatus.value,
          tower_id: this.selectedTower.value,
          unit_id: this.selectedUnit.value,
          value_date_from: this.valueDate
            ? format(this.valueDate[0], appStrings.DATEFNSFORMAT)
            : null,
          value_date_to: this.valueDate
            ? format(this.valueDate[1], appStrings.DATEFNSFORMAT)
            : null
        }
      };
      this.loader = true;
      this.$store
        .dispatch('fmsTransaction/getBillBatchesById', this.payload)
        .then(response => {
          this.loader = false;
          this.showAdvanceSearch = false;
          if (response.status === 200) {
            this.searchBatchBillList = response.data.data.page.map(data => {
              data.selectbox = false;
              data.df = true;
              return data;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getBatchBillData() {
      this.showBatchBillDetailGrid = true;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.text = item.org_name;
        this.selectedLegalEntity.value = item.org_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.selectedOU.text = item.org_name;
        this.selectedOU.value = item.org_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.selectedBillingCycle.text = item.billing_cycle_name;
        this.selectedBillingCycle.id = item.billing_cycle_hdr_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.selectedFmsProj.text = item.fms_prj_name;
        this.selectedFmsProj.value = item.proj_id;
      } else if (this.vsetCode === 'BILL_BATCH_STATUS') {
        this.selectedBillStatus.value = item.value_code;
        this.selectedBillStatus.text = item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE[this.tower]) {
        this.selectedTower = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.selectedFloor.text = null;
        this.selectedFloor.value = null;
        this.selectedUnit.text = null;
        this.selectedUnit.value = null;
        this.selectedSubUnit.text = null;
        this.selectedSubUnit.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE[this.floor]) {
        this.selectedFloor = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.selectedTower = {
          text: item.tower_name,
          value: item.tower_id
        };
        this.selectedUnit.text = null;
        this.selectedUnit.value = null;
        this.selectedSubUnit.text = null;
        this.selectedSubUnit.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE[this.unit]) {
        this.selectedUnit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.selectedBatch.plan_unit_type_vset === 'FMS_SUB_UNIT'
          ? (this.selectedTower = {
              text: item.tower_name,
              value: item.tower_id
            })
          : (this.selectedTower = {
              text: item.lease_tower_name,
              value: item.lease_tower_id
            });
        this.selectedBatch.plan_unit_type_vset === 'FMS_SUB_UNIT'
          ? (this.selectedFloor = {
              text: item.floor_name,
              value: item.floor_id
            })
          : (this.selectedFloor = {
              text: item.lease_floor_name,
              value: item.lease_floor_id
            });
        this.selectedSubUnit.text = null;
        this.selectedSubUnit.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.selectedTower = {
          text: item.tower_name,
          value: item.tower_id
        };
        this.selectedFloor = {
          text: item.floor_name,
          value: item.floor_id
        };
        this.selectedUnit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.selectedSubUnit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.isDependent = false;
      this.depVsetParam = this.selectedBatch.project_id;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE[this.tower]) {
        this.parent_value_set_id = this.selectedBatch.project_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE[this.floor]) {
        this.parent_value_set_id = this.selectedTower.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE[this.unit]) {
        this.depTowerParam = this.selectedTower.value;
        this.parent_value_set_id = this.selectedFloor.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.depTowerParam = this.selectedTower.value;
        this.depFloorParam = this.selectedFloor.value;
        this.parent_value_set_id = this.selectedUnit.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.OU_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE
      ) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    advanceSearch(flag) {
      this.showAdvanceSearch = flag;
    },
    clearSearch() {
      this.createdBy = null;
      this.customerName = null;
      this.selectedBillStatus = {
        value: null,
        text: null
      };
      this.billDate = null;
      this.dueDate = null;
      this.glDate = null;
      this.valueDate = null;
      this.selectedPocket = this.keyValue;
      this.selectedTower = this.keyValue;
      this.selectedFloor = this.keyValue;
      this.selectedUnit = this.keyValue;
      this.selectedSubUnit = this.keyValue;
      this.getBillBatchDataById();
    },
    checkUncheckAll() {
      this.searchBatchBillList = this.searchBatchBillList.map(data => {
        if (data.request_num) {
          if (!/error/i.test(data.status)) {
            data.selectbox = false;
          } else {
            data.selectbox = this.selectAllCheckBox;
          }
        } else {
          data.selectbox = this.selectAllCheckBox;
        }
        return data;
      });
    },
    getErrorDataById(id) {
      this.payload = {
        billBatchId:id,
        pagination: {
          _page: this.currentPage - 1,
          _limit: this.perPage
        },
      };
      this.loader = true;
      this.$store
        .dispatch('fmsTransaction/getBatchErrorById', this.payload)
        .then(response => {
          this.loader = false;
          this.showAdvanceSearch = false;
          if (response.status === 200) {
            this.errorData=response.data.data.page;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      if(item.status==="ERRORS"){
        this.showErrorDetailsModal = true;
        this.getErrorDataById(item.bill_batch_id);
      } else{
        this.billData = item;
        this.showBillingDetailsModel = true;
      }
    },
    hideBillingDetailsModal(flag) {
      this.showBillingDetailsModel = flag;
    },
    hideErrorDetailsModal(flag) {
      this.showErrorDetailsModal = flag;
    },
    validateCheckBox(billRow) {
      if (billRow.request_num) {
        if (!/error/i.test(billRow.status)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'BILL_BATCH_STATUS') {
        this.selectedBillStatus.value = null;
        this.selectedBillStatus.text = null;
      } else if (vsetCode === appStrings.VALUESETTYPE[this.tower]) {
        this.selectedTower = {
          text: null,
          value: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE[this.floor]) {
        this.selectedFloor = {
          text: null,
          value: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE[this.unit]) {
        this.selectedUnit = {
          text: null,
          value: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.selectedSubUnit = {
          text: null,
          value: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
